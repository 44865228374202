import React, { useState } from 'react'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import circle from '../../assets/img/circle.svg';
import Seo from '../seo';

const HowItWorksComponent = (props) => {
  const [activeId, setActiveId] = useState('');
  const Steps = props.steps
  const texts = props.texts
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }

  return (
    <>
    <Seo title={props.seoTitle} description={props.seoDescription} />
    <div className="howItWorksSlide" id='how-it-works'>
      <div className="slideContent">
        <div className="mainContainer">
          <div className='textCenter howItWorksTitle'>3 easy steps</div>
          <div className="stepsListHolder">
            <div className="stepsList">
              {Steps.map((step) => {
                const isActive = step.id === activeId;
                const img = getImage(step.img);
                return (
                  <div onMouseEnter={() => window.innerWidth > 1350 ? setActiveId(step.id) : setActiveId('')} className={isActive ? "slItem slItemActive" : "slItem"} key={step.id}>
                    <div className={isActive ? "slItemCircle slItemCircleActive" : "slItemCircle"}>
                      <img src={circle} alt="circle" className={isActive ? 'circleActive' : 'responsiveCircle'} />
                      <div className="slItemImg">
                        <GatsbyImage image={img} alt={'Step ' + step.id} />
                        <h2 className="title">{t(step.title, texts)}</h2>
                      </div>
                    </div>
                    <div className={isActive ? "textPart textPartActive" : "textPart"}>
                      <div className="text">{t(step.text, texts)}</div>
                    </div>
                  </div>
                );
              }
              )}
            </div>
          </div>
        </div>
      </div>
    </div></>
  )
}

export default HowItWorksComponent