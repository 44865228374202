import React from 'react'
import ABanner from "../features/ab.component";
import { StaticQuery, graphql } from "gatsby";
import Seo from '../seo';

export function formatFloat(num) {
  // Convert number to string with 3 decimal places
  const threeDecimals = num.toFixed(3);

  // Check if the third decimal place is zero
  if (threeDecimals.charAt(threeDecimals.length - 1) === '0') {
    // If the last decimal is zero, return with two decimal places
    return Number(threeDecimals).toFixed(2);
  } else {
    // Otherwise, return with three decimal places
    return threeDecimals;
  }
}


const PriceComponent = (props) => {
  const data = props.data
  const texts = props.data.allTexts.edges[0].node
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }

  return (
    <>
    <Seo title={props.seoTitle} description={props.seoDescription} />
    <div className="priceSlide">
      <div className="slideContent">
        <div className="mainContainer gutter120">
          <div className="containerPrice">
            <div className='textCenter textPrimary titlePrice mb30' id='price'>{t(data.allDataJson.edges[0].node.priceList.title, texts)}</div>
            <div className="priceTableHolder">
              <table className="priceTable">
                <thead>
                  <tr>
                    <th><span className="priceTableTitle">{t(data.allDataJson.edges[0].node.priceList.leftColumn, texts)}</span></th>
                    <th><span className="priceTableTitle">{t(data.allDataJson.edges[0].node.priceList.rightColumn, texts)}</span></th>
                  </tr>
                </thead>
                <tbody>
                  {data.allPrices.edges.map(({ node }, i) => {
                    const amount = parseFloat(node.amount);
                    return (
                      <tr key={i}>
                        <td><span className="qntText">{node.text}</span></td>
                        <td>
                          {/*<span className="qnt">${node.amount ? parseFloat(node.amount).toFixed(2) : '-'}</span> &nbsp; {node.per_text}*/}
                          <span className="qntText">{amount ? `$${formatFloat(amount)}` : '-'}</span> &nbsp; <span className="qntText">{node.per_text}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="tableTextHolder">
                {data.allDataJson.edges[0].node.priceList.captions.map((caption, i) => {
                  return (
                    <p key={i}>
                      {t(caption, texts)}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mainContainer" style={{ padding: '0' }}>
            <ABanner />
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

const PriceComponentQuery = (props) => (
  <StaticQuery
    query={graphql`
        query PricesQuery {
          allPrices {
            edges {
              node {
                amount
                currency
                id
                per_text
                text
              }
            }
          }
          allDataJson {
            edges {
              node {
                priceList {
                  captions
                  leftColumn
                  rightColumn
                  title
                }
              }
            }
          }
          allTexts {
            edges {
              node {
                priceList_captions_0
                priceList_captions_1
                priceList_captions_2
                priceList_captions_3
                priceList_captions_4
                priceList_captions_5
                priceList_captions_6
                priceList_captions_7
                priceList_leftColumn
                priceList_rightColumn
                priceList_title
              }
            }
          }
        }
      `}
    render={(data) => <PriceComponent data={data} {...props}/>}
  />
)


export default PriceComponentQuery

