import React  from 'react'
import { Helmet } from 'react-helmet';
import IntroComponent from "../components/intro/intro.component";
import HowItWorksComponent from "../components/how-it-works/how-it-works.component";
import FeaturesComponent from "../components/features/features.component";
import {MainLayout} from "../layouts/main.layout";
import PriceComponent from "../components/price/price.component";
import {graphql} from "gatsby";
import Seo from '../components/seo';

const Index = (props) => {
  const Features = props.data.allDataJson.edges[0].node.featuresList;
  const Steps = props.data.allDataJson.edges[0].node.stepsList;
  const Texts = props.data.allTexts.edges[0].node

  return (
    <MainLayout location={props.location} isAbsolute={true}>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
        </script>
        {/*<script src="https://io.dropinblog.com/embedjs/5e03db61-a107-4fa4-9337-2f0a4760a919.js"></script>*/}
      </Helmet>
      <div className='landingSlidesHolder'>
        <IntroComponent
        />
        <HowItWorksComponent
          steps={Steps}
          texts={Texts}
        />
          <FeaturesComponent features={Features} texts={Texts}/>
        <PriceComponent
        />
      </div>
    </MainLayout>
    )
}

export default Index

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          featuresList {
            id
            text
            title
            img {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: AUTO)
              }
            }
            smallImg {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: AUTO)
              }
            }
          }
          stepsList {
            id
            text
            title
            img {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    allTexts {
      edges {
        node {
          stepsList_0_text
          stepsList_0_title
          stepsList_1_text
          stepsList_1_title
          stepsList_2_text
          stepsList_2_title
          featuresList_0_text
          featuresList_0_title
          featuresList_1_text
          featuresList_1_title
          featuresList_2_text
          featuresList_2_title
          featuresList_3_text
          featuresList_3_title
          featuresList_4_text
          featuresList_4_title
          featuresList_5_text
          featuresList_5_title
        }
      }
    }
  }
`

export const Head = () => (
  <Seo title="UploadLetters.com - Mail Letters Online" description="Mail letters online. Send Certified Mail, First-Class Mail, and Priority Mail instantly. We handle the printing and mailing process. Guaranteed next-day service." />
)

